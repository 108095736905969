<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title class="d-flex justify-space-between mb-3">
          Video Inputs
          <div>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              outlined
              dense
              hide-details
            ></v-text-field>
          </div>
        </v-card-title>
        <v-data-table
          :search="search"
          :headers="headers"
          :items="getVideoInputs"
          :loading="getVideoInputsLoading"
          item-key="id"
          class="elevation-1 data-table"
          multi-sort
        >
          <template v-slot:item.status="{ item }">
            <div class="text-capitalize">
              {{ item.status }}
            </div>
          </template>
          <template v-slot:item.updated_at="{ item }">
            <div>
              {{ item.updated_at | datetime }}
            </div>
          </template>
          <template v-slot:item.action="{ item }">
            <div class="d-flex">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="success"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="$router.push(`/app/video-input/${item.id}/edit`)"
                  >
                    <v-icon>mdi-pencil-box-outline</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  metaInfo: {
    title: "Video Inputs",
  },
  data() {
    return {
      search: "",
      selected: null,
      headers: [
        { text: "Student", value: "student.name", sortable: false },
        { text: "Course", value: "course.description", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Comment", value: "comment", sortable: false },
        { text: "Last Updated", value: "updated_at", sortable: false },
        { text: "Action", value: "action", sortable: false },
      ],
    };
  },

  computed: {
    ...mapGetters(["getVideoInputs", "getVideoInputsLoading"]),
  },

  filters: {
    datetime(value) {
      return moment(value).format("YYYY-MM-DD HH:mm");
    },
  },
};
</script>
<style lang="scss" scoped></style>
